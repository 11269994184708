import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import DatatableFunc from "../../../Common/DatatableFunc";
import Http from "../../../Common/Http";
import { LoaderComponent } from "../../../Common/LoaderComponent";
import IsLogin from "../../../Common/IsLogin";

const List = () => {
  const [receiveData, setReceiveData] = useState([]);

  const fetchData = () => {
    Http.get("/distributor/retailer-order-return").then((res) => {
      setReceiveData(res.data);
    });
  };

  useEffect(() => {
    DatatableFunc();
    fetchData();
  }, []);

  return (
    <div>
      <IsLogin></IsLogin>
      <LoaderComponent />
      <div>
        <div className="am-mainpanel">
          <div className="am-pagetitle">
            <h5 className="am-title">All Retailer Order Return</h5>
          </div>
          <div className="am-pagebody">
            <div className="card pd-20 pd-sm-40">
              {/* <h6 className="card-body-title mg-sm-b-30">Datatable</h6> */}
              <div className="row">
                <div className="table-responsive p-0 pb-2">
                  <table className="table table-sm table-bordered table-striped align-items-center justify-content-center mb-0 datatable">
                    <thead>
                      <tr>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          S/N
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          D.Date
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          O.Date
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Invoice No
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Customer Name
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Inv. Total
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Received Amount
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Distributor Received Status
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Customer Care Received Status
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Actions
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {receiveData.map((item, index) => (
                        <tr key={item.id}>
                          <td className="text-xs">{index + 1}</td>
                          <td className="text-xs">{item.delivery_date}</td>
                          <td className="text-xs">{item.date}</td>
                          <td className="text-xs">{item.receipt_no}</td>
                          <td className="text-xs">
                            {item.customer ? item.customer.shop_name : ""}
                          </td>
                          <td className="text-xs">{item.total_price}</td>
                          <td className="text-xs">{item.payment_amount}</td>
                          <td className="text-xs">
                            {item.distributor_recieved == 1 ? "Received" : ""}
                          </td>
                          <td className="text-xs">
                            {item.customer_care_recieved == 1 ? "Received" : ""}
                          </td>
                          <td className="text-xs">
                            <Link
                              to={{
                                pathname:
                                  "/distributor/retailer-order-return/" +
                                  item.id,
                              }}
                              className="btn btn-info btn-sm"
                            >
                              <i className="fa fa-list" aria-hidden="true"></i>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
