import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import Alert from "../../../Common/Alert";
import DatatableFunc from "../../../Common/DatatableFunc";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";
import { LoaderComponent } from "../../../Common/LoaderComponent";

import ReactDataTable from "../../../Common/ReactDataTable";

const Staff = () => {
  const location = useLocation();
  const [receiveData, setReceiveData] = useState([]);
  const [alert, setAlert] = useState(null);
  const [grade, setGrade] = useState({});
  const [department, setDepartment] = useState({});
  const [designation, setDesignation] = useState({});
  const [companyLocation, setCompanyLocation] = useState({});
  const [inputs, setInputs] = useState({});
  const [role, setRole] = useState(null);

  const fetchData = () => {
    Http.get("/staff").then((res) => {
      setReceiveData(res.data);
    });
    Http.get("/staff-type-check").then((res) => {
      setRole(res.data);
    });
    // Http.get("/grade").then((res) => {
    //   setGrade(res.data);
    // });

    Http.get("/gradeSelect2").then((res) => {
      setGrade(res.data);
    });

    // Http.get("/department").then((res) => {
    //   setDepartment(res.data);
    // });

    Http.get("/department2").then((res) => {
      setDepartment(res.data);
    });

    // Http.get("/designation").then((res) => {
    //   setDesignation(res.data);
    // });

    Http.get("/designationSelect2").then((res) => {
      setDesignation(res.data);
    });

    // Http.get("/location").then((res) => {
    //   setCompanyLocation(res.data);
    // });

    Http.get("/locationSelect2").then((res) => {
      setCompanyLocation(res.data);
    });
  };

  useEffect(() => {
    DatatableFunc();
    fetchData();
  }, []);

  const showAlert = (message, type) => {
    setAlert({
      message: message,
      type: type,
    });
    setTimeout(() => {
      setAlert(null);
    }, 4000);
  };

  useEffect(() => {
    if (!location.state) {
      return;
    }
    showAlert(location.state.message, location.state.type);
  }, [location]);

  const deleteData = (id, deleteApiUrl, showMsg) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Http.delete("/" + deleteApiUrl + "/" + id).then((res) => {
          setReceiveData(res.data);
          Swal.fire("Deleted!", showMsg + " has been deleted.", "success");
        });
      }
    });
  };

  const selectHandleChange = (selectedOption, actionMeta) => {
    const name = actionMeta.name;
    const value = selectedOption.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  //  Set Input Value with state
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  // Form Submit
  const formSubmit = () => {
    const queryParams = new URLSearchParams(inputs).toString();
    Http.get(`/staff?${queryParams}`).then((res) => {
      setReceiveData(res.data);
    });
  };

  const cols = [
    { field: "card_no", header: "Card No" },
    {
      header: "Name",
      field: "name",
    },
    // {
    //   header: "Name",
    //   field: (rowData) => (
    //     <Link to={`/profile/${rowData.id}`}>{rowData.name}</Link>
    //   ),
    // },
    { field: "email", header: "Email" },
    { field: "mobile", header: "Mobile" },
    { field: "workplace.name", header: "Workplace" },
    { field: "designation.name", header: "Designation" },
    { field: "department.name", header: "Department" },
    { field: "company_location.name", header: "Location" },
    { field: "joining_date", header: "Joining Date" },
    { field: "blood_group", header: "Blood Group" },
    { field: "date_of_birth", header: "Date Of Birth" },
    { field: "type", header: "Type" },
    { field: "status", header: "Status" },
    { field: "salary_structure_latest.gross", header: "Gross Salary" },
  ];

  return (
    <div>
      <IsLogin></IsLogin>
      <LoaderComponent />
      <div>
        <div className="am-mainpanel">
          <div className="am-pagetitle">
            <h5 className="am-title">All Staff</h5>
            <Link to="/create-staff" className="btn btn-primary">
              <i className="fa fa-plus-square mg-r-6"></i>Staff
            </Link>
          </div>

          <div className="am-pagebody">
            <Alert alert={alert} />

            <div className="card pd-sm-40">
              {/* <h6 className="card-body-title mg-sm-b-30">Datatable</h6> */}
              <div className="row">
                <div className="col-lg">
                  <label>Department</label>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={department[0]}
                    name="department_id"
                    options={department}
                    onChange={selectHandleChange}
                  />
                </div>
                <div className="col-lg">
                  <label>Grade</label>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={grade[0]}
                    name="grade_id"
                    options={grade}
                    onChange={selectHandleChange}
                  />
                </div>
                <div className="col-lg">
                  <label>Designation</label>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={designation[0]}
                    name="designation_id"
                    options={designation}
                    onChange={selectHandleChange}
                  />
                </div>
                <div className="col-lg">
                  <label>Company Location</label>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={companyLocation[0]}
                    name="company_location_id"
                    options={companyLocation}
                    onChange={selectHandleChange}
                  />
                </div>
                <div className="col-lg">
                  <label>Type</label>
                  <select
                    className="form-control"
                    name="type"
                    onChange={handleChange}
                  >
                    <option value="">Select Type</option>
                    <option value="permanent">Permanent</option>
                    <option value="contractual">Contractual</option>
                    <option value="internship">Internship</option>
                  </select>
                </div>
                <div className="col-lg">
                  <label>Status</label>
                  <select
                    className="form-control"
                    name="status"
                    onChange={handleChange}
                  >
                    <option value="">Select Status</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>
                </div>
                <div className="col-lg">
                  <label>.</label>
                  <button
                    className="btn btn-primary btn-block mg-b-10"
                    onClick={formSubmit}
                  >
                    <i className="fa fa-send mg-r-10"></i> Search
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <ReactDataTable
              cols={cols}
              receiveData={receiveData}
              actionTemplate={actionTemplate}
              deleteData={deleteData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const actionTemplate = (rowData, deleteData) => {
  return (
    <td className="text-xs text-nowrap">
      <Link to={`/profile/${rowData.id}`} className="btn btn-light btn-sm mr-1">
        {" "}
        <i className="fa fa-list" aria-hidden="true"></i>{" "}
      </Link>
      <Link
        to={{ pathname: "/edit-staff/" + rowData.id }}
        className="btn btn-primary btn-sm mr-1"
      >
        <i className="fa fa-pencil" aria-hidden="true"></i>
      </Link>
      <Link
        to={{
          pathname: "/appointment-letter/" + rowData.id,
        }}
        className="btn btn-info btn-sm mr-1"
      >
        <i className="fa fa-list" aria-hidden="true"></i>
      </Link>
      <Link
        to={{ pathname: "/idcard/" + rowData.id }}
        className="btn btn-warning btn-sm mr-1"
      >
        <i className="fa fa-id-card" aria-hidden="true"></i>
      </Link>
      {rowData.user_type == "so" ||
      rowData.user_type == "dsr" ||
      rowData.user_type == "rsm" ||
      rowData.user_type == "am" ? (
        <>
          <Link
            to={{
              pathname: "/create-route-plan/" + rowData.id,
            }}
            className="btn btn-success btn-sm mr-1"
          >
            <i className="fa fa-map-marker" aria-hidden="true"></i>
          </Link>
          <Link
            to={{
              pathname: "/edit-staff-pass/" + rowData.id,
            }}
            className="btn btn-secondary btn-sm mr-1"
          >
            <i className="fa fa-key" aria-hidden="true"></i>
          </Link>
          <Link
            to={{
              pathname:
                "/tracking-reports/attendance-report-details/mdo/" +
                rowData.id +
                "/0",
            }}
            className="btn btn-info btn-sm mx-1"
            title="View"
          >
            <i className="fa fa-list" aria-hidden="true"></i>
          </Link>
        </>
      ) : (
        ""
      )}

      <button
        className="btn btn-danger btn-sm"
        onClick={() => deleteData(rowData.id, "staff", "Staff")}
      >
        <i className="fa fa-trash"></i>
      </button>
    </td>
  );
};

export default Staff;
