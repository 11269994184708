import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DatatableFunc from "../../../Common/DatatableFunc";
import Alert from "../../../Common/Alert";
import Http from "../../../Common/Http";
import { LoaderComponent } from "../../../Common/LoaderComponent";
import IsLogin from "../../../Common/IsLogin";
import Swal from "sweetalert2";

const List = () => {
  const navigate = useNavigate();
  const [receiveData, setReceiveData] = useState([]);
  const [inputs, setInputs] = useState({
    start_date: getCurrentDate(),
    end_date: getCurrentDate(),
    type: "order_date",
  });
  const [error, setError] = useState({});
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [role, setRole] = useState(null);

  function getCurrentDate() {
    const dateObj = new Date();
    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    const day = ("0" + dateObj.getDate()).slice(-2);
    const year = dateObj.getFullYear();
    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (receiveData.length > 0) {
      DatatableFunc();
    }
  }, [receiveData]);

  const fetchData = async () => {
    try {
      const roleResponse = await Http.get("/staff-type-check");
      setRole(roleResponse.data);

      const ordersResponse = await Http.get("/distributor/retailer-order");
      setReceiveData(ordersResponse.data);

      const filteredIds = ordersResponse.data
        .filter(
          (item) =>
            item.payment_status === "paid" &&
            item.distributor_receive_amount_status !== 1
        )
        .map((item) => item.id);

      setSelectedCheckboxes(filteredIds);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCheckboxChange = (event) => {
    const value = Number(event.target.value);
    setSelectedCheckboxes((prev) =>
      event.target.checked
        ? [...prev, value]
        : prev.filter((id) => id !== value)
    );
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const formSubmit = async () => {
    const submissionInputs = {
      ...inputs,
      item_id: selectedCheckboxes,
    };

    try {
      const response = await Http.get(
        `/distributor/retailer-order?from=${submissionInputs.start_date}&to=${submissionInputs.end_date}&type=${submissionInputs.type}`
      );
      setReceiveData(response.data);
    } catch (error) {
      console.error("Error on form submit:", error);
    }
  };

  const paymentReceive = async () => {
    if (selectedCheckboxes.length > 0) {
      const submissionInputs = {
        ...inputs,
        item_id: selectedCheckboxes,
      };

      try {
        const response = await Http.post(
          "/distributor/distributor-payment-receive",
          submissionInputs
        );
        alert("Successfully Received Amount");
        setReceiveData(response.data);
      } catch (e) {
        console.error("Error on payment receive:", e);
      }
    } else {
      alert("Please Select Minimum 1 Checkbox to Receive Payment Amount");
    }
  };

  const deleteData = (id, deleteApiUrl, showMsg) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Http.delete("/" + deleteApiUrl + "/" + id).then((res) => {
          setReceiveData(res.data);
          Swal.fire("Deleted!", showMsg + " has been deleted.", "success");
        });
      }
    });
  };

  return (
    <div>
      <IsLogin></IsLogin>
      <LoaderComponent />
      <div>
        <div className="am-mainpanel">
          <div className="am-pagetitle">
            <h5 className="am-title">All Retailer Order</h5>
          </div>
          <div className="am-pagebody">
            <div className="card pd-sm-40">
              {/* <h6 className="card-body-title mg-sm-b-30">Datatable</h6> */}
              <div className="row">
                <div className="col-lg">
                  <input
                    type="date"
                    className="form-control"
                    name="start_date"
                    value={inputs.start_date}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg">
                  <input
                    type="date"
                    className="form-control"
                    name="end_date"
                    value={inputs.end_date}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg">
                  <select
                    className="form-control"
                    name="type"
                    onChange={handleChange}
                  >
                    <option value="order_date">Search By Order Date</option>
                    <option value="delivery_date">
                      Search By Delivery Date
                    </option>
                    <option value="payment_receive_date">
                      Search By Payment Receive Date
                    </option>
                    <option value="payment_yet_to_receive">
                      Search By Payment Yet to Receive
                    </option>
                    <option value="todays_delivery">
                      Search By Todays Delivery
                    </option>
                  </select>
                </div>
                <div className="col-lg">
                  <button
                    className="btn btn-primary btn-block mg-b-10"
                    onClick={formSubmit}
                  >
                    <i className="fa fa-send mg-r-10"></i> Search
                  </button>
                </div>
                <div className="col-lg">
                  <button
                    className="btn btn-info btn-block mg-b-10"
                    onClick={paymentReceive}
                  >
                    <i className="fa fa-send mg-r-10"></i> Payment Receive
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="am-pagebody">
            <div className="card pd-20 pd-sm-40">
              {/* <h6 className="card-body-title mg-sm-b-30">Datatable</h6> */}
              <div className="row">
                <div className="table-responsive p-0 pb-2">
                  <table
                    className={`table table-sm table-bordered table-striped align-items-center justify-content-center mb-0 ${
                      receiveData.length > 0 ? "datatable" : ""
                    }`}
                  >
                    <thead>
                      <tr>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          #
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          S/N
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          D.Date
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          O.Date
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Invoice No
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Customer Name
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Inv. Total
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Received
                        </th>
                        <th className="text-uppercase text-dark text-sm font-weight-bolder ps-2">
                          Actions
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {receiveData.map((item, index) => (
                        <tr key={item.id}>
                          <td className="text-xs text-center">
                            {role == "distributor" ? (
                              item.payment_status == "paid" &&
                              item.distributor_receive_amount_status != 1 ? (
                                <input
                                  type="checkbox"
                                  name="amount_receive"
                                  value={item.id} // Ensure item.id is the correct type
                                  onChange={handleCheckboxChange}
                                  checked={selectedCheckboxes.includes(item.id)}
                                />
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )}
                          </td>
                          <td className="text-xs">{index + 1}</td>
                          <td className="text-xs">{item.delivery_date}</td>
                          <td className="text-xs">{item.date}</td>
                          <td className="text-xs">{item.receipt_no}</td>
                          <th className="text-xs">
                            {item.customer
                              ? item.customer.phone +
                                " - " +
                                item.customer.shop_name
                              : ""}
                          </th>
                          <th className="text-xs">{item.total_price}</th>
                          <th className="text-xs">{item.payment_amount}</th>
                          <td className="text-xs">
                            <Link
                              to={{
                                pathname:
                                  "/distributor/retailer-order/" + item.id,
                              }}
                              className="btn btn-info btn-sm"
                            >
                              <i className="fa fa-list" aria-hidden="true"></i>
                            </Link>
                            {item.payment_status == "unpaid" &&
                            item.delivery_status == "undelivered" ? (
                              <>
                                <button
                                  className="btn btn-danger btn-sm ms-1"
                                  onClick={() =>
                                    deleteData(
                                      item.id,
                                      "distributor/retailer-order-delete",
                                      "Order"
                                    )
                                  }
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              </>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
