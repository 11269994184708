import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";
import { LoaderComponent } from "../../../Common/LoaderComponent";
import useRequiredFields from "../../../../hooks/useRequiredFields";
import FormValidation from "../../../Common/FormValidation";

const Edit = () => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const { id } = useParams();
  const [error, setError] = useState({});
  const [base, setBase] = useState({});
  const [rsm, setRsm] = useState({});
  const [factory, setFactory] = useState({});
  const [selectedBaseOption, setSelectedBaseOption] = useState(null);
  const [selectedSrBase, setSelectedSrBase] = useState([]);
  const { checkRequiredFields } = useRequiredFields();
  const [isRequired, setIsRequired] = useState(false);
  // const [division, setDivision] = useState([]);
  // const [district, setDistrict] = useState([]);
  // const [thana, setThana] = useState([]);
  // const [place, setPlace] = useState([]);
  // const [selectedDivision, setSelectedDivision] = useState(null);
  // const [selectedDistrict, setSelectedDistrict] = useState(null);
  // const [selectedThana, setSelectedThana] = useState(null);
  // const [selectedPlace, setSelectedPlace] = useState(null);

  // if upload has any File
  const [selectedFile1, setSelectedFile1] = useState();
  const [selectedFile2, setSelectedFile2] = useState();
  const [selectedFile3, setSelectedFile3] = useState();
  const [selectedFile4, setSelectedFile4] = useState();

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  /*const selectHandleChange = (selectedOption, actionMeta) => {
    const name = actionMeta.name;
    const value = selectedOption.value;
    setInputs((values) => ({ ...values, [name]: value }));
    if (name == "division_id") {
      setSelectedDivision({
        label: selectedOption.label,
        value: selectedOption.value,
      });

      setSelectedDistrict([]);
      setSelectedThana([]);
      setSelectedPlace([]);
      Http.get(
        "/select2/SupplyChainPurchaseManagement.Setting.District/id,name/division_id:" +
          value
      ).then((res) => {
        setDistrict(res.data);
      });
    } else if (name == "district_id") {
      setSelectedDistrict({
        label: selectedOption.label,
        value: selectedOption.value,
      });
      Http.get(
        "/select2/SupplyChainPurchaseManagement.Setting.Thana/id,name/district_id:" +
          value
      ).then((res) => {
        setThana(res.data);
      });

      setSelectedThana([]);
    } else if (name == "thana_id") {
      setSelectedThana({
        label: selectedOption.label,
        value: selectedOption.value,
      });

      Http.get(
        "/select2/SupplyChainPurchaseManagement.Setting.Place/id,name/thana_id:" +
          value
      ).then((res) => {
        setPlace(res.data);
      });
    } else if (name == "place_id") {
      setSelectedThana({
        label: selectedOption.label,
        value: selectedOption.value,
      });
    }
  };*/

  const handleSelect2Change = (selectedOption, actionMeta) => {
    const name = actionMeta.name;
    const value = selectedOption.value;

    if (actionMeta.name === "base_id") {
      setSelectedBaseOption({
        label: selectedOption.label,
        value: selectedOption.value,
      });
    }

    setInputs((values) => ({ ...values, [name]: value }));
  };
  const fetchData = () => {
    let data = {};
    data.designation_id = 7;
    Http.post("/staff-filter", data).then((res) => {
      setRsm(res.data);
    });
    Http.get("/base2").then((res) => {
      setBase(res.data);
    });
    Http.get("/factory").then((res) => {
      setFactory(res.data);
    });

    Http.get("/distributor/" + id + "/edit").then((res) => {
      setInputs({
        code: res.data.code,
        name: res.data.name,
        contact_person: res.data.contact_person,
        contact_phone: res.data.contact_phone,
        secondary_phone: res.data.secondary_phone,
        email: res.data.email,
        commission: res.data.commission,
        opening_date: res.data.opening_date,
        previous_due: res.data.previous_due,
        present_address: res.data.present_address,
        permanent_address: res.data.permanent_address,
        godown_address: res.data.godown_address,
        business_type: res.data.business_type,
        factory_id: res.data.factory_id,
        base_id: res.data.base_id,
        opened_by: res.data.opened_by,
        factory_id: res.data.factory_id,
        status: res.data.status,
        inactive_date: res.data.inactive_date,
        resign_date: res.data.resign_date,
        nid: res.data.nid_number,
        // division_id: res.data.division_id,
        // district_id: res.data.district_id,
        // thana_id: res.data.thana_id,
        // place_id: res.data.place_id,
        bin_no: res.data.bin_no,
        license_no: res.data.distributor_documents
          ? res.data.distributor_documents.license_no
          : "",
        license_no_validaty_date: res.data.distributor_documents
          ? res.data.distributor_documents.license_no_validaty_date
          : "",
        vat_reg_no: res.data.distributor_documents
          ? res.data.distributor_documents.vat_reg_no
          : "",
        vat_reg_no_validaty_date: res.data.distributor_documents
          ? res.data.distributor_documents.vat_reg_no_validaty_date
          : "",
        tin_no: res.data.distributor_documents
          ? res.data.distributor_documents.tin_no
          : "",
        tin_no_validaty_date: res.data.distributor_documents
          ? res.data.distributor_documents.tin_no_validaty_date
          : "",
      });

      let srbase = [];
      Object.keys(res.data.sr_base).forEach((key) => {
        srbase.push({
          label: res.data.sr_base[key].base.name,
          value: res.data.sr_base[key].base.id,
        });
      });
      setSelectedSrBase(srbase);

      /*setSelectedDivision({
        label: res.data.division.name,
        value: res.data.division.id,
      });

      setSelectedDistrict({
        label: res.data.district.name,
        value: res.data.district.id,
      });

      setSelectedThana({
        label: res.data.thana.name,
        value: res.data.thana.id,
      });

      setSelectedPlace({
        label: res.data.place.name,
        value: res.data.place.id,
      });*/

      setSelectedBaseOption({
        label: res.data.distributor_location
          ? res.data.distributor_location.name
          : "",
        value: res.data.distributor_location ? res.data.base_id : 0,
      });

      /*Http.get(
        "/select2/SupplyChainPurchaseManagement.Setting.District/id,name/division_id:" +
          res.data.division_id
      ).then((res) => {
        setDistrict(res.data);
      });

      Http.get(
        "/select2/SupplyChainPurchaseManagement.Setting.Thana/id,name/district_id:" +
          res.data.district_id
      ).then((res) => {
        setThana(res.data);
      });

      Http.get(
        "/select2/SupplyChainPurchaseManagement.Setting.Place/id,name/thana_id:" +
          res.data.thana_id
      ).then((res) => {
        setPlace(res.data);
      });*/
    });

    /*Http.get(
      "/select2/SupplyChainPurchaseManagement.Setting.Division/id,name"
    ).then((res) => {
      setDivision(res.data);
    });*/
  };
  useEffect(() => {
    fetchData();
  }, []);

  // if upload has any File
  const handleFileChange1 = (e) => {
    setSelectedFile1(e.target.files[0]);
  };
  const handleFileChange2 = (e) => {
    setSelectedFile2(e.target.files[0]);
  };
  const handleFileChange3 = (e) => {
    setSelectedFile3(e.target.files[0]);
  };
  const handleFileChange4 = (e) => {
    setSelectedFile4(e.target.files[0]);
  };

  const formSubmit = () => {
    inputs.sr_base = selectedSrBase;
    Http.put("/distributor/" + id, inputs)
      .then((res) => {
        navigate("/distributors", {
          state: { message: res.data.message, type: res.data.type },
        });
      })
      .catch(function (e) {
        setError(e.response.data.errors);
      });
  };

  const selectSrBaseChange = (selectedValues) => {
    setSelectedSrBase(selectedValues);
  };

  return (
    <div>
      <IsLogin></IsLogin>
      <LoaderComponent />
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Edit Distributor</h5>
        </div>
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            <h6 className="card-body-title mg-sm-b-30">
              Edit Distributor Form
            </h6>
            <div className="row">
              <div className="col-lg">
                <FormValidation error={error} />
              </div>
            </div>
            <div className="row">
              <div className="col-lg">
                <label>
                  Code <i className="fa fa-star required"></i>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="code"
                  value={inputs.code || ""}
                  onChange={handleChange}
                  placeholder="Input code"
                />
              </div>
              <div className="col-lg">
                <label>
                  Distributor Name <i className="fa fa-star required"></i>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={inputs.name || ""}
                  onChange={handleChange}
                  placeholder="Input Name"
                />
              </div>
              <div className="col-lg">
                <label>
                  Contact Person <i className="fa fa-star required"></i>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="contact_person"
                  value={inputs.contact_person || ""}
                  onChange={handleChange}
                  placeholder="Input Contact Person"
                />
              </div>

              <div className="col-lg">
                <label>Contact Phone</label>
                <input
                  type="number"
                  className="form-control"
                  name="contact_phone"
                  value={inputs.contact_phone || ""}
                  onChange={handleChange}
                  placeholder="Input Contact Phone"
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-lg">
                <label>Secondary Phone</label>
                <input
                  type="number"
                  className="form-control"
                  name="secondary_phone"
                  value={inputs.secondary_phone || ""}
                  onChange={handleChange}
                  placeholder="Input Secondary Phone"
                />
              </div>
              <div className="col-lg">
                <label>
                  Email <i className="fa fa-star required"></i>
                </label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  value={inputs.email || ""}
                  onChange={handleChange}
                  placeholder="Input email"
                />
              </div>
              <div className="col-lg">
                <label>Password</label>
                <input
                  type="password"
                  className="form-control"
                  name="Password"
                  value={inputs.Password || ""}
                  onChange={handleChange}
                  placeholder="Input Password"
                />
              </div>
              <div className="col-lg">
                <label>Opening Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="opening_date"
                  value={inputs.opening_date || ""}
                  onChange={handleChange}
                  placeholder="Input opening_date"
                />
              </div>
            </div>

            {/* <div className="row mt-3">
              <div className="col-lg">
                <label htmlFor="">
                  Division <i className="fa fa-star required"></i>
                </label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={selectedDivision}
                  value={selectedDivision}
                  onChange={selectHandleChange}
                  name="division_id"
                  options={division}
                />
              </div>
              <div className="col-lg">
                <label htmlFor="">
                  District <i className="fa fa-star required"></i>
                </label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={selectedDistrict}
                  value={selectedDistrict}
                  onChange={selectHandleChange}
                  name="district_id"
                  options={district}
                />
              </div>
              <div className="col-lg">
                <label htmlFor="">
                  Thana <i className="fa fa-star required"></i>
                </label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={selectedThana}
                  value={selectedThana}
                  onChange={selectHandleChange}
                  name="thana_id"
                  options={thana}
                />
              </div>

              <div className="col-lg">
                <label htmlFor="">
                  Place <i className="fa fa-star required"></i>
                </label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={selectedPlace}
                  value={selectedPlace}
                  onChange={selectHandleChange}
                  name="place_id"
                  options={place}
                />
              </div>
            </div> */}
            <div className="row mt-3">
              <div className="col-lg">
                <label>Present Address</label>
                <textarea
                  className="form-control"
                  name="present_address"
                  onChange={handleChange}
                  defaultValue={inputs.present_address}
                ></textarea>
              </div>
              <div className="col-lg">
                <label>Permanent Address</label>
                <textarea
                  className="form-control"
                  name="permanent_address"
                  onChange={handleChange}
                  defaultValue={inputs.permanent_address}
                ></textarea>
              </div>
              <div className="col-lg">
                <label>Godown Address</label>
                <textarea
                  className="form-control"
                  name="godown_address"
                  onChange={handleChange}
                  defaultValue={inputs.godown_address}
                ></textarea>
              </div>
              <div className="col-lg">
                <label>
                  Business Type <i className="fa fa-star required"></i>
                </label>
                <select
                  className="form-control"
                  name="business_type"
                  required=""
                  onChange={handleChange}
                >
                  <option value="">Select Type</option>
                  <option
                    value="retail"
                    selected={inputs.business_type === "retail" ? true : false}
                  >
                    Retail
                  </option>
                  <option
                    value="wholeSale"
                    selected={
                      inputs.business_type === "wholeSale" ? true : false
                    }
                  >
                    Wholesale
                  </option>
                  <option
                    value="commission"
                    selected={
                      inputs.business_type === "commission" ? true : false
                    }
                  >
                    Commission
                  </option>
                  <option
                    value="institute"
                    selected={
                      inputs.business_type === "institute" ? true : false
                    }
                  >
                    Institute
                  </option>
                  <option
                    value="temporary"
                    selected={
                      inputs.business_type === "temporary" ? true : false
                    }
                  >
                    Temporary
                  </option>
                  <option
                    value="rdso"
                    selected={inputs.business_type === "rdso" ? true : false}
                  >
                    RDSO
                  </option>
                </select>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg">
                <label>
                  Distributor Base <i className="fa fa-star required"></i>
                </label>
                {/* <select
                  className="form-control"
                  name="base_id"
                  required=""
                  onChange={handleChange}
                >
                  <option value="">Select base</option>
                  {Array.from(base).map((item, index) => (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select> */}
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={selectedBaseOption}
                  value={selectedBaseOption}
                  onChange={handleSelect2Change}
                  name="base_id"
                  options={base}
                />
              </div>
              {/* <div className="col-lg">
                <label>Region</label>
                <select
                  className="form-control"
                  name="business_type"
                  disabled="disabled"
                  onChange={handleChange}
                >
                  {Array.from(base).map((item, index) => (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-lg">
                <label>Area</label>
                <select
                  className="form-control"
                  name="business_type"
                  disabled="disabled"
                  onChange={handleChange}
                >
                  {Array.from(base).map((item, index) => (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div> */}
              <div className="col-lg">
                <label>
                  Distributor Opened By <i className="fa fa-star required"></i>
                </label>
                <select
                  className="form-control"
                  name="opened_by"
                  onChange={handleChange}
                >
                  <option value="">Select RSM</option>
                  {Array.from(rsm).map((item, index) => (
                    <option
                      value={item.id}
                      key={item.id}
                      selected={inputs.opened_by === item.id ? true : false}
                    >
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-lg">
                <label>
                  SR Base <i className="fa fa-star required"></i>
                </label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  value={selectedSrBase} // Set the selected value here
                  name="sr_base"
                  options={base}
                  text="Please select Base."
                  isMulti="yes"
                  onChange={selectSrBaseChange}
                  {...(isRequired && {
                    styles: {
                      control: (base, { menuIsOpen, hasValue }) => {
                        const borderColor = hasValue
                          ? "#ccc"
                          : menuIsOpen
                          ? "#ccc"
                          : "red";
                        return { ...base, borderColor };
                      },
                    },
                  })}
                />
              </div>

              <div className="col-lg">
                <label>
                  Commission <i className="fa fa-star required"></i>
                </label>
                <input
                  type="number"
                  step="any"
                  className="form-control"
                  name="commission"
                  value={inputs.commission || ""}
                  onChange={handleChange}
                  placeholder="Input Commission Rate"
                />
              </div>
            </div>
            {/* NID */}
            <div className="row mt-3">
              <div className="col-lg">
                <label>
                  Assign To a Factory <i className="fa fa-star required"></i>
                </label>
                <select
                  className="form-control"
                  name="factory_id"
                  onChange={handleChange}
                >
                  <option value="">Select Factory</option>
                  {Array.from(factory).map((item, index) => (
                    <option
                      value={item.id}
                      key={item.id}
                      selected={inputs.factory_id === item.id ? true : false}
                    >
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-lg">
                <label>Bin No</label>
                <input
                  type="text"
                  className="form-control"
                  name="bin_no"
                  value={inputs.bin_no || ""}
                  onChange={handleChange}
                  placeholder="Input Bin no"
                />
              </div>

              <div className="col-lg">
                <label>Previous Due</label>
                <input
                  type="number"
                  className="form-control"
                  name="previous_due"
                  value={inputs.previous_due || ""}
                  onChange={handleChange}
                  placeholder="Input Previous Due"
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg">
                <label>NID No</label>
                <input
                  type="text"
                  className="form-control"
                  name="nid"
                  value={inputs.nid || ""}
                  onChange={handleChange}
                  placeholder="Input license no"
                />
              </div>
              <div className="col-lg">
                <label>NID Img</label>
                <input
                  type="file"
                  className="form-control"
                  name="nid_img"
                  onChange={handleFileChange4}
                  placeholder="Input license no"
                />
              </div>
            </div>

            {/* license */}
            <div className="row mt-3">
              <div className="col-lg">
                <label>License No</label>
                <input
                  type="text"
                  className="form-control"
                  name="license_no"
                  value={inputs.license_no || ""}
                  onChange={handleChange}
                  placeholder="Input license no"
                />
              </div>
              <div className="col-lg mg-t-10 mg-lg-t-0">
                <label>Validity Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="license_no_validaty_date"
                  value={inputs.license_no_validaty_date || ""}
                  onChange={handleChange}
                  placeholder="Input contact phone"
                />
              </div>
              <div className="col-lg">
                <label>License Img</label>
                <input
                  type="file"
                  className="form-control"
                  name="license_img"
                  onChange={handleFileChange1}
                  placeholder="Input license no"
                />
              </div>
            </div>

            {/* Vat */}
            <div className="row mt-3">
              <div className="col-lg">
                <label>Vat Reg. No</label>
                <input
                  type="text"
                  className="form-control"
                  name="vat_reg_no"
                  value={inputs.vat_reg_no || ""}
                  onChange={handleChange}
                  placeholder="Input license no"
                />
              </div>
              <div className="col-lg mg-t-10 mg-lg-t-0">
                <label>Validity Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="vat_reg_no_validaty_date"
                  value={inputs.vat_reg_no_validaty_date || ""}
                  onChange={handleChange}
                  placeholder="Input contact phone"
                />
              </div>
              <div className="col-lg">
                <label>Vat Img</label>
                <input
                  type="file"
                  className="form-control"
                  name="vat_reg_img"
                  onChange={handleFileChange2}
                  placeholder="Input license no"
                />
              </div>
            </div>

            {/* TIN */}
            <div className="row mt-3">
              <div className="col-lg">
                <label>TIN No</label>
                <input
                  type="text"
                  className="form-control"
                  name="tin_no"
                  value={inputs.tin_no || ""}
                  onChange={handleChange}
                  placeholder="Input license no"
                />
              </div>
              <div className="col-lg mg-t-10 mg-lg-t-0">
                <label>Validity Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="tin_no_validaty_date"
                  value={inputs.tin_no_validaty_date || ""}
                  onChange={handleChange}
                  placeholder="Input contact phone"
                />
              </div>
              <div className="col-lg">
                <label>Vat Img</label>
                <input
                  type="file"
                  className="form-control"
                  name="tin_img"
                  onChange={handleFileChange3}
                  placeholder="Input license no"
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg">
                <label>
                  Status <i className="fa fa-star required"></i>
                </label>
                <select
                  className="form-control"
                  name="status"
                  required=""
                  onChange={handleChange}
                >
                  <option
                    value="active"
                    selected={inputs.status === "active" ? true : false}
                  >
                    Active
                  </option>
                  <option
                    value="inactive"
                    selected={inputs.status === "inactive" ? true : false}
                  >
                    inactive
                  </option>
                  <option
                    value="resign"
                    selected={inputs.status === "resign" ? true : false}
                  >
                    resign
                  </option>
                </select>
              </div>

              <div className="col-lg">
                <label>Inactive Date (If Status is Inactive)</label>
                <input
                  type="date"
                  className="form-control"
                  name="inactive_date"
                  value={inputs.inactive_date || ""}
                  onChange={handleChange}
                  placeholder="Input inactive_date"
                />
              </div>
              <div className="col-lg">
                <label>Resign Date (If Status is Resign)</label>
                <input
                  type="date"
                  className="form-control"
                  name="resign_date"
                  value={inputs.resign_date || ""}
                  onChange={handleChange}
                  placeholder="Input resign_date"
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-3">
                <button
                  className="btn btn-primary btn-block mg-b-10"
                  onClick={formSubmit}
                >
                  <i className="fa fa-send mg-r-10"></i> Update
                </button>
              </div>
            </div>
          </div>
          {/* card */}
        </div>
      </div>
    </div>
  );
};

export default Edit;
