import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Alert from "../../../../Common/Alert";
import DatatableFunc from "../../../../Common/DatatableFunc";
import Http from "../../../../Common/Http";
import IsLogin from "../../../../Common/IsLogin";
import Swal from "sweetalert2";
import { LoaderComponent } from "../../../../Common/LoaderComponent";

import ReactDataTable from "../../../../Common/ReactDataTable";

const List = () => {
  // State Call
  const location = useLocation();
  const [receiveData, setReceiveData] = useState([]);
  const [alert, setAlert] = useState(null);
  const [inputs, setInputs] = useState({});
  const [region, setRegion] = useState({});
  const [area, setArea] = useState({});
  const [base, setBase] = useState({});

  // Auto Load
  const fetchData = () => {
    Http.get("/route").then((res) => {
      setReceiveData(res.data);
    });

    // Region Load
    Http.get("/region").then((res) => {
      setRegion(res.data);
    });
  };

  useEffect(() => {
    DatatableFunc();
    fetchData();
  }, []);

  // Area Load Region Change Wise
  const areaRegionWise = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    Http.get("/region-wise-area/" + value).then((res) => {
      setArea(res.data);
    });

    setInputs((values) => ({ ...values, [name]: value }));
  };

  // Base Load Region Change Wise
  const baseRegionWise = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    Http.get("/area-wise-base/" + value).then((res) => {
      setBase(res.data);
    });

    setInputs((values) => ({ ...values, [name]: value }));
  };

  //  Set Input Value with state
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  // Alert
  const showAlert = (message, type) => {
    setAlert({
      message: message,
      type: type,
    });
    setTimeout(() => {
      setAlert(null);
    }, 4000);
  };

  useEffect(() => {
    if (!location.state) {
      return;
    }
    showAlert(location.state.message, location.state.type);
  }, [location]);

  const deleteData = (id, deleteApiUrl, showMsg) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Http.delete("/" + deleteApiUrl + "/" + id).then((res) => {
          setReceiveData(res.data);
          Swal.fire("Deleted!", showMsg + " has been deleted.", "success");
        });
      }
    });
  };

  // Form Submit
  const formSubmit = () => {
    Http.post("/route-filter", inputs).then((res) => {
      setReceiveData(res.data);
    });
  };

  const cols = [
    { field: "name", header: "Name" },
    { field: "placeNames", header: "Places" },
    { field: "base.name", header: "Base" },
    { field: "area.name", header: "Area" },
    { field: "region.name", header: "Region" },
    { field: "started_from", header: "Started From" },
    { field: "remarks", header: "Remarks" },
    { field: "status", header: "Status" },
  ];

  const mappedData = receiveData.map((item) => {
    const placeNames = item.places
      .map((place) => place?.place?.name)
      .join(" || ");

    return {
      ...item,
      placeNames,
    };
  });

  return (
    <div>
      <IsLogin></IsLogin>
      <LoaderComponent />
      <div>
        <div className="am-mainpanel">
          <div className="am-pagetitle">
            <h5 className="am-title">All Route</h5>
            <Link to="/createroute" className="btn btn-primary">
              <i className="fa fa-plus-square mg-r-6"></i>Route
            </Link>
          </div>

          <div className="am-pagebody">
            <Alert alert={alert} />

            <div className="card pd-sm-40">
              {/* <h6 className="card-body-title mg-sm-b-30">Datatable</h6> */}
              <div className="row">
                <div className="col-lg">
                  <select
                    className="form-control"
                    name="work_place_region_id"
                    onChange={areaRegionWise}
                  >
                    <option value="">Select Region</option>
                    {Array.from(region).map((item, index) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                  </select>
                </div>
                <div className="col-lg">
                  <select
                    className="form-control"
                    name="work_place_area_id"
                    onChange={baseRegionWise}
                  >
                    <option value="">Select Area</option>
                    {Array.from(area).map((item, index) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                  </select>
                </div>
                <div className="col-lg">
                  <select
                    className="form-control"
                    name="work_place_base_id"
                    onChange={handleChange}
                  >
                    <option value="">Select Base</option>
                    {Array.from(base).map((item, index) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                  </select>
                </div>
                <div className="col-lg">
                  <button
                    className="btn btn-primary btn-block mg-b-10"
                    onClick={formSubmit}
                  >
                    <i className="fa fa-send mg-r-10"></i> Search
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <ReactDataTable
              cols={cols}
              receiveData={mappedData}
              actionTemplate={actionTemplate}
              deleteData={deleteData}
            />
          </div>

        </div>
      </div>
    </div>
  );
};

const actionTemplate = (rowData, deleteData) => {
  return (
    <div className="text-xs text-nowrap">
      <Link
        to={{
          pathname: "/editroute/" + rowData.id,
          state: { id: rowData.id },
        }}
        className="btn btn-primary btn-sm"
        title="Edit"
      >
        <i className="fa fa-pencil" aria-hidden="true"></i>
      </Link>
      {/* <button
        className="btn btn-danger btn-sm ms-1"
        onClick={() => deleteData(rowData.id, "route", "route")}
        title="Delete"
      >
        <i className="fa fa-trash"></i>
      </button> */}
    </div>
  );
};

export default List;
