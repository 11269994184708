import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import Http from "../../../Common/Http";
import IsLogin from "../../../Common/IsLogin";
import FormValidation from "../../../Common/FormValidation";
import useRequiredFields from "../../../../hooks/useRequiredFields";
import { LoaderComponent } from "../../../Common/LoaderComponent";

const CreateStaff = () => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({
    user_type: "so",
    basic: 0,
    house_rent: 0,
    medical: 0,
    conveyence: 0,
    entertainment: 0,
    ta_da: 0,
    holiday: 0,
    insurance: 0,
    income_tax: 0,
    other_allowance: 0,
    canteen_bill: 0,
    phone_bill_limit: 0,
    insurance_rate: 0,
    gross: 0,
    department_head: false,
    approved_by: false,
  });
  const [grade, setGrade] = useState({});
  const [department, setDepartment] = useState({});
  const [designation, setDesignation] = useState({});
  const [companyLocation, setCompanyLocation] = useState({});
  const [grossSalary, setGrossSalary] = useState(0);
  const [cardNo, setCardNo] = useState({});
  const [error, setError] = useState({});
  const { id } = useParams();
  const { checkRequiredFields } = useRequiredFields();
  const [zone, setZone] = useState({});
  const [region, setRegion] = useState({});
  const [area, setArea] = useState({});
  const [base, setBase] = useState({});

  const [validationMessages, setValidationMessages] = useState({
    work_place_zone_id: "",
    work_place_region_id: "",
    work_place_area_id: "",
    work_place_base_id: "",
  });

  const validateSelectFields = () => {
    let isValid = true;
    let messages = { ...validationMessages };

    if (!inputs.work_place_zone_id) {
      messages.work_place_zone_id = "Zone is required";
      isValid = false;
    } else {
      messages.work_place_zone_id = "";
    }

    if (!inputs.work_place_region_id) {
      messages.work_place_region_id = "Region is required";
      isValid = false;
    } else {
      messages.work_place_region_id = "";
    }

    if (!inputs.work_place_area_id) {
      messages.work_place_area_id = "Area is required";
      isValid = false;
    } else {
      messages.work_place_area_id = "";
    }

    if (!inputs.work_place_base_id) {
      messages.work_place_base_id = "Base is required";
      isValid = false;
    } else {
      messages.work_place_base_id = "";
    }

    setValidationMessages(messages);
    return isValid;
  };

  const experienceInitialState = [
    { company_name: "", start_date: "", end_date: "", year_of_experience: "" },
    { company_name: "", start_date: "", end_date: "", year_of_experience: "" },
  ];
  const [experience, setExperience] = useState(experienceInitialState);

  // Default Load
  const fetchData = () => {
    if (id > 0) {
      Http.get("/candidate/" + id).then((res) => {
        setInputs({
          name: res.data.name,
          email: res.data.email,
          mobile: res.data.mobile,
          candidate_id: res.data.id,
        });
      });
    }
    Http.get("/grade").then((res) => {
      setGrade(res.data);
    });

    Http.get("/select2/Hr.WorkPlaceZone/id,name").then((res) => {
      setZone(res.data);
    });

    // Http.get("/select2/Hr.WorkPlaceRegion/id,name").then((res) => {
    //   setRegion(res.data);
    // });

    Http.get("/department2").then((res) => {
      setDepartment(res.data);
    });
    Http.get("/designationSelect2").then((res) => {
      setDesignation(res.data);
    });

    Http.get("/locationSelect2").then((res) => {
      setCompanyLocation(res.data);
    });
    Http.get("/last-code-increment-by-one/hr_staff_basic_infos/card_no/S").then(
      (res) => {
        setInputs((values) => ({ ...values, card_no: res.data }));
        // setCardNo(res.data);
      }
    );
  };
  useEffect(() => {
    fetchData(); // Get All Fetch Info/Data
  }, []);
  // End Default Load
  const handleGradeChange = (e) => {
    const name = e.target.name; // Chanages Input Name
    const value = e.target.value; // Changes Input Value
    setInputs((values) => ({ ...values, [name]: value })); // Set Input With Value and Name in State

    Http.get("/editGrade/" + value).then((res) => {
      // Load Salary Structure based on Grade Changes

      setInputs((values) => ({ ...values, ["basic"]: res.data.basic_salary }));
      setInputs((values) => ({
        ...values,
        ["house_rent"]: res.data.house_rent,
      }));
      setInputs((values) => ({
        ...values,
        ["medical"]: res.data.medical_allowance,
      }));
      setInputs((values) => ({
        ...values,
        ["conveyence"]: res.data.conveyance_allowance,
      }));
      setInputs((values) => ({
        ...values,
        ["entertainment"]: res.data.entertainment_allowance,
      }));
      setInputs((values) => ({
        ...values,
        ["ta_da"]: res.data.tada_allowance,
      }));
      setInputs((values) => ({
        ...values,
        ["holiday"]: res.data.holiday_rate,
      }));
      setInputs((values) => ({
        ...values,
        ["insurance"]: res.data.insurance_rate,
      }));
      setInputs((values) => ({
        ...values,
        ["income_tax"]: res.data.income_tax,
      }));
      setInputs((values) => ({
        ...values,
        ["canteen_bill"]: res.data.canteen_bill,
      }));
      setInputs((values) => ({
        ...values,
        ["phone_bill_limit"]: res.data.phone_bill_limit,
      }));
      setInputs((values) => ({
        ...values,
        ["insurance_rate"]: res.data.insurance_rate,
      }));
      setGrossSalary(res.data.gross_salary); // Set Gross Salary with Grade Default Information
    });
  };

  const selectHandleChange = (selectedOption, actionMeta) => {
    const name = actionMeta.name;
    const value = selectedOption.value;
    setInputs((values) => ({ ...values, [name]: value }));
    if (name == "work_place_zone_id") {
      Http.get(
        "/select2/Hr.WorkPlaceRegion/id,name/work_place_zone_id:" + value
      ).then((res) => {
        setRegion(res.data);
      });
    }
    if (name == "work_place_region_id") {
      Http.get(
        "/select2/Hr.WorkPlaceArea/id,name/work_place_region_id:" + value
      ).then((res) => {
        setArea(res.data);
      });
    }
    if (name == "work_place_area_id") {
      Http.get(
        "/select2/Hr.WorkPlaceBase/id,name/work_place_area_id:" + value
      ).then((res) => {
        setBase(res.data);
      });
    }
  };

  // Set All Input State
  const handleChange = (e) => {
    const name = e.target.name; // Chanages Input Name
    const value = e.target.value; // Changes Input Value
    setInputs((values) => ({ ...values, [name]: value })); // Set Input With Value and Name in State
  };

  const toggleChange = (e) => {
    const name = e.target.name; // Chanages Input Name
    const value = !e.target.value; // Changes Input Value
    setInputs((values) => ({ ...values, [name]: value })); // Set Input With Value and Name in State
  };

  // Region Load Zone Change Wise
  const regionZoneWise = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    Http.get("/zone-wise-region/" + value).then((res) => {
      setRegion(res.data);
    });

    setInputs((values) => ({ ...values, [name]: value }));
  };

  // Area Load Region Change Wise
  const areaRegionWise = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    Http.get("/region-wise-area/" + value).then((res) => {
      setArea(res.data);
    });

    setInputs((values) => ({ ...values, [name]: value }));
  };

  // Base Load Region Change Wise
  const baseRegionWise = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    Http.get("/area-wise-base/" + value).then((res) => {
      setBase(res.data);
    });

    setInputs((values) => ({ ...values, [name]: value }));
  };

  // Add More Experience
  const addMoreExperience = () => {
    setExperience([
      ...experience,
      {
        company_name: "",
        start_date: "",
        end_date: "",
        year_of_experience: "",
      },
    ]); // Add One More Experience
  };
  // Remove Experience
  const removeExperience = (index) => {
    const list = [...experience]; // Load All Existing experience State Data
    list.splice(index, 1); // Remove Experience by Index/Click Event Wise
    setExperience(list); // Replace Existing experience into State
  };
  // Set Experience State
  const handleInputChange = (e, index) => {
    const { name, value } = e.target; // Chanages Experience Input Name and Value
    const list = [...experience]; // Defined Experience State All Information into List Variable
    list[index][name] = value;
    setExperience(list);
  };

  // Gross Salary Chk and Set the Salary Structure State
  const salaryChk = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));

    setGrossSalary(
      parseInt(inputs.basic) +
        parseInt(inputs.house_rent) +
        parseInt(inputs.medical) +
        parseInt(inputs.conveyence) +
        parseInt(inputs.entertainment) +
        parseInt(inputs.ta_da) +
        parseInt(inputs.holiday) +
        parseInt(inputs.insurance) +
        parseInt(inputs.income_tax) +
        parseInt(inputs.other_allowance) +
        parseInt(inputs.canteen_bill) +
        parseInt(inputs.phone_bill_limit) +
        parseInt(inputs.insurance_rate)
    );
  };

  // Form Submit Send Data to Server
  const formSubmit = () => {
    const isSelectFieldsValid = validateSelectFields();
    if (checkRequiredFields() && isSelectFieldsValid) {
      inputs.experience = experience; //Set Experience State to Input State
      inputs.gross = grossSalary;

      Http.post("/staff", inputs)
        .then((res) => {
          // Send all Input data to Staff Route For Save to server
          navigate("/staff", {
            state: { message: res.data.message, type: "success" },
          }); // Redirect to Staff Route after Save Data
        })
        .catch(function (e) {
          setError(e.response.data.errors);
        });
    }
  };

  return (
    <div>
      <IsLogin></IsLogin>
      <LoaderComponent />
      <div className="am-mainpanel">
        <div className="am-pagetitle">
          <h5 className="am-title">Create Staff</h5>
        </div>
        <div className="am-pagebody">
          <div className="card pd-20 pd-sm-40">
            <h6 className="card-body-title mg-sm-b-30">Basic Information</h6>
            <div className="row">
              <div className="col-lg">
                <FormValidation error={error} />
              </div>
            </div>
            <div className="row">
              <div className="col-lg">
                <label htmlFor="">
                  Card No <i className="fa fa-star required"></i>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Card No"
                  name="card_no"
                  value={inputs.card_no}
                  onChange={handleChange}
                  data-required
                />
              </div>
              <div className="col-lg">
                <label htmlFor="">
                  Grade <i className="fa fa-star required"></i>
                </label>
                {/* <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={grade[0]}
                  name="grade_id"
                  options={grade}
                  onChange={selectGradeHandleChange}
                /> */}

                <select
                  className="form-control"
                  name="grade_id"
                  onChange={handleGradeChange}
                >
                  <option value="">Select Grade</option>
                  {Array.from(grade).map((item, index) => (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-lg">
                <label htmlFor="">
                  Department <i className="fa fa-star required"></i>
                </label>

                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={department[0]}
                  name="department_id"
                  options={department}
                  onChange={selectHandleChange}
                />

                {/* <select
                  className="form-control"
                  name="department_id"
                  onChange={handleChange}
                >
                  <option value="">Select Department</option>
                  {Array.from(department).map((item, index) => (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select> */}
              </div>
              <div className="col-lg">
                <label htmlFor="">
                  Name <i className="fa fa-star required"></i>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  name="name"
                  value={inputs.name || ""}
                  onChange={handleChange}
                  data-required
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg">
                <label htmlFor="">
                  Mobile <i className="fa fa-star required"></i>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="mobile"
                  name="mobile"
                  value={inputs.mobile || ""}
                  onChange={handleChange}
                  data-required
                />
              </div>
              <div className="col-lg">
                <label htmlFor="">
                  Type <i className="fa fa-star required"></i>
                </label>
                <select
                  className="form-control"
                  name="type"
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Type</option>
                  <option value="permanent">Permanent</option>
                  <option value="contractual">Contractual</option>
                  <option value="internship">Internship</option>
                </select>
              </div>
              <div className="col-lg">
                <label htmlFor="">
                  Joining Date <i className="fa fa-star required"></i>
                </label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Joining Date"
                  name="joining_date"
                  value={inputs.joining_date || ""}
                  onChange={handleChange}
                  data-required
                />
              </div>
              <div className="col-lg">
                <label htmlFor="">Inactive Date</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Inactive Date"
                  name="inactive_date"
                  value={inputs.inactive_date || ""}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg">
                <label htmlFor="">
                  Email <i className="fa fa-star required"></i>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email"
                  name="email"
                  value={inputs.email || ""}
                  onChange={handleChange}
                  data-required
                />
              </div>
              <div className="col-lg">
                <label htmlFor="">
                  Password <i className="fa fa-star required"></i>
                </label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="password"
                  name="password"
                  value={inputs.password || ""}
                  onChange={handleChange}
                  data-required
                />
              </div>
              <div className="col-lg">
                <label htmlFor="">
                  Designation <i className="fa fa-star required"></i>
                </label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={designation[0]}
                  name="designation_id"
                  options={designation}
                  onChange={selectHandleChange}
                />

                {/* <select
                  className="form-control"
                  name="designation_id"
                  onChange={handleChange}
                >
                  <option value="">Select Designation</option>
                  {Array.from(designation).map((item, index) => (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select> */}
              </div>
              <div className="col-lg">
                <label htmlFor="">
                  Company Location <i className="fa fa-star required"></i>
                </label>

                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={companyLocation[0]}
                  name="company_location_id"
                  options={companyLocation}
                  onChange={selectHandleChange}
                />

                {/* <select
                  className="form-control"
                  name="company_location_id"
                  onChange={handleChange}
                >
                  <option value="">Company Location</option>
                  {Array.from(companyLocation).map((item, index) => (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select> */}
              </div>
              {/* <div className="col-lg">
                <label htmlFor=""></label>
                <select className="form-control" name="workplace_id" onChange={handleChange}>
                  <option value="">Base Area </option>
                  {Array.from(workplace).map((item, index) => (
                    <option value={item.id} key={item.id}>{item.name}</option>
                  ))}
                </select>
              </div> */}
            </div>
            <div className="row mt-3">
              {/* <div className="col-lg">
                <label for="">
                  Select Region <i class="fa fa-star required"></i>
                </label>
                <select
                  className="form-control"
                  name="work_place_region_id"
                  onChange={areaRegionWise}
                >
                  <option value="">Select Region</option>
                  {Array.from(region).map((item, index) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </select>
              </div> */}
              <div className="col-lg">
                <label htmlFor="">
                  Zone <i className="fa fa-star required"></i>
                </label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={zone[0]}
                  name="work_place_zone_id"
                  options={zone}
                  onChange={selectHandleChange}
                />
                {validationMessages.work_place_zone_id && (
                  <div className="error-message">
                    {validationMessages.work_place_zone_id}
                  </div>
                )}
              </div>
              <div className="col-lg">
                <label htmlFor="">
                  Region <i className="fa fa-star required"></i>
                </label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={region[0]}
                  name="work_place_region_id"
                  options={region}
                  onChange={selectHandleChange}
                />
                {validationMessages.work_place_region_id && (
                  <div className="error-message">
                    {validationMessages.work_place_region_id}
                  </div>
                )}
              </div>
              <div className="col-lg">
                <label for="">
                  Select Area <i class="fa fa-star required"></i>
                </label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={area[0]}
                  name="work_place_area_id"
                  options={area}
                  onChange={selectHandleChange}
                />
                {validationMessages.work_place_area_id && (
                  <div className="error-message">
                    {validationMessages.work_place_area_id}
                  </div>
                )}
              </div>
              <div className="col-lg">
                <label for="">
                  Select Base <i class="fa fa-star required"></i>
                </label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={base[0]}
                  name="work_place_base_id"
                  options={base}
                  onChange={selectHandleChange}
                />
                {validationMessages.work_place_base_id && (
                  <div className="error-message">
                    {validationMessages.work_place_base_id}
                  </div>
                )}
              </div>
              <div className="col-lg mt-2">
                <div className="form-check">
                  <input
                    type="checkbox"
                    name="department_head"
                    className="form-check-input"
                    value={inputs.department_head || ""}
                    defaultChecked={inputs.department_head}
                    onChange={toggleChange}
                    id="department_head"
                  />
                  <label className="form-check-label" for="department_head">
                    Department Head
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    name="approved_by"
                    className="form-check-input"
                    value={inputs.approved_by || ""}
                    defaultChecked={inputs.approved_by}
                    onChange={toggleChange}
                    id="approved_by"
                  />
                  <label className="form-check-label" for="approved_by">
                    Approver
                  </label>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg">
                <label htmlFor="">Bank Account</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Bank Account"
                  name="bank_account"
                  value={inputs.bank_account || ""}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg">
                <label htmlFor="">Blood Group</label>
                <select
                  className="form-control"
                  name="blood_group"
                  onChange={handleChange}
                >
                  <option value="">Blood Group</option>
                  <option value="A+">A+</option>
                  <option value="A-">A-</option>
                  <option value="B+">B+</option>
                  <option value="B-">B-</option>
                  <option value="O+">O+</option>
                  <option value="O-">O-</option>
                  <option value="AB+">AB+</option>
                  <option value="AB-">AB-</option>
                </select>
              </div>
              <div className="col-lg">
                <label htmlFor="">
                  Date Of Birth <i className="fa fa-star required"></i>
                </label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Date of Birth"
                  name="date_of_birth"
                  value={inputs.date_of_birth || ""}
                  onChange={handleChange}
                  data-required
                />
              </div>
              <div className="col-lg">
                <label htmlFor="">
                  Emergency Contact <i className="fa fa-star required"></i>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Emergency Contact"
                  name="emergency_contact"
                  value={inputs.emergency_contact || ""}
                  onChange={handleChange}
                  data-required
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg">
                <label htmlFor="">Reference</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Referrence"
                  name="reference"
                  value={inputs.reference || ""}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg">
                <label htmlFor="">Permanent Address</label>
                <textarea
                  className="form-control"
                  placeholder="Permanent Address"
                  name="permanent_address"
                  value={inputs.permanent_address || ""}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg">
                <label htmlFor="">
                  Present Address <i className="fa fa-star required"></i>
                </label>
                <textarea
                  className="form-control"
                  placeholder="Present Address"
                  name="present_address"
                  value={inputs.present_address || ""}
                  onChange={handleChange}
                  data-required
                />
              </div>
              <div className="col-lg">
                <label htmlFor="">Notes</label>
                <textarea
                  className="form-control"
                  placeholder="Notes"
                  name="notes"
                  value={inputs.notes || ""}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg">
                <label htmlFor="">
                  ROLE <i className="fa fa-star required"></i>
                </label>
                <select
                  className="form-control"
                  name="user_type"
                  onChange={handleChange}
                  required
                >
                  <option value="customer-care">Customer Care</option>
                  <option value="so">MDO</option>
                  <option value="staff">staff</option>
                  <option value="dsr">dsr</option>
                  <option value="admin">admin</option>
                  <option value="am">am</option>
                  <option value="rsm">rsm</option>
                  <option value="hos">hos</option>
                  <option value="hr">hr</option>
                  <option value="accounts">accounts</option>
                  <option value="central_warehouse">central_warehouse</option>
                  <option value="depot">depot</option>
                  <option value="sd">sd</option>
                  <option value="qc">qc</option>
                  <option value="distributor_manager">
                    distributor_manager
                  </option>
                  <option value="supply_chain">supply_chain</option>
                  <option value="production">production</option>
                  <option value="ppic">ppic</option>
                  <option value="rm_pm">rm_pm</option>
                  <option value="reports">reports</option>
                  <option value="area_manager">area_manager</option>
                  <option value="moo">MOO</option>
                  <option value="cdm">CDM</option>
                </select>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg">
                <h6 className="card-body-title mg-sm-b-30">Work Experience</h6>
              </div>
              <div className="col-lg">
                <label htmlFor=""></label>
                <button
                  className="btn btn-info float-right"
                  onClick={addMoreExperience}
                >
                  +
                </button>
              </div>
              <hr></hr>
            </div>
            {Array.from(experience).map((x, i) => (
              <div className="row mt-3" key={i}>
                <div className="col-lg">
                  <label htmlFor="">Comapny Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Company Name"
                    name="company_name"
                    value={x.company_name || ""}
                    onChange={(e) => handleInputChange(e, i)}
                  />
                </div>
                <div className="col-lg">
                  <label htmlFor="">Start Date</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Start Date"
                    name="start_date"
                    value={x.start_date || ""}
                    onChange={(e) => handleInputChange(e, i)}
                  />
                </div>
                <div className="col-lg">
                  <label htmlFor="">End Date</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="End Date"
                    name="end_date"
                    value={x.end_date || ""}
                    onChange={(e) => handleInputChange(e, i)}
                  />
                </div>
                <div className="col-lg">
                  <label htmlFor="">Year Of Experience</label>
                  <div className="row">
                    <div className="col-lg-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Year Of Experience"
                        name="year_of_experience"
                        value={x.year_of_experience || ""}
                        onChange={(e) => handleInputChange(e, i)}
                      />
                    </div>
                    <div className="col-lg-3">
                      {experience.length > 1 ? (
                        <button
                          className="btn btn-danger float-right"
                          onClick={() => removeExperience(i)}
                        >
                          Remove
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="row mt-5">
              <div className="col-lg">
                <h6 className="card-body-title mg-sm-b-30">Salary Structure</h6>
              </div>
              <hr></hr>
            </div>
            <div className="row mt-5">
              <div className="col-lg">
                <label htmlFor="">
                  Basic <i className="fa fa-star required"></i>
                </label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Basic"
                  name="basic"
                  value={inputs.basic || ""}
                  onChange={salaryChk}
                  data-required
                />
              </div>
              <div className="col-lg">
                <label htmlFor="">House Rent</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="House Rent"
                  name="house_rent"
                  value={inputs.house_rent || ""}
                  onChange={salaryChk}
                />
              </div>
              <div className="col-lg">
                <label htmlFor="">Medical</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Medical"
                  name="medical"
                  value={inputs.medical || ""}
                  onChange={salaryChk}
                />
              </div>
              <div className="col-lg">
                <label htmlFor="">Conveyence</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Conveyence"
                  name="conveyence"
                  value={inputs.conveyence || ""}
                  onChange={salaryChk}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg">
                <label htmlFor="">Entertainment</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Entertainment"
                  name="entertainment"
                  value={inputs.entertainment || ""}
                  onChange={salaryChk}
                />
              </div>
              <div className="col-lg">
                <label htmlFor="">Ta Da</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Ta Da"
                  name="ta_da"
                  value={inputs.ta_da || ""}
                  onChange={salaryChk}
                />
              </div>
              <div className="col-lg">
                <label htmlFor="">Holiday</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Holiday"
                  name="holiday"
                  value={inputs.holiday || ""}
                  onChange={salaryChk}
                />
              </div>
              <div className="col-lg">
                <label htmlFor="">Insurance</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Insurance"
                  name="insurance"
                  value={inputs.insurance || ""}
                  onChange={salaryChk}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg">
                <label htmlFor="">Income Tax</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Income Tax"
                  name="income_tax"
                  value={inputs.income_tax || ""}
                  onChange={salaryChk}
                />
              </div>
              <div className="col-lg">
                <label htmlFor="">Other Allowance</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Other Allowance"
                  name="other_allowance"
                  value={inputs.other_allowance || ""}
                  onChange={salaryChk}
                />
              </div>
              <div className="col-lg">
                <label htmlFor="">Canteen Bill</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Canteen Bill"
                  name="canteen_bill"
                  value={inputs.canteen_bill || ""}
                  onChange={salaryChk}
                />
              </div>
              <div className="col-lg">
                <label htmlFor="">Phone Bill Limit</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Phone Bill Limit"
                  name="phone_bill_limit"
                  value={inputs.phone_bill_limit || ""}
                  onChange={salaryChk}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg">
                <label htmlFor="">Insurance Rate</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Insurance Rate"
                  name="insurance_rate"
                  value={inputs.insurance_rate || ""}
                  onChange={salaryChk}
                />
              </div>
              <div className="col-lg">
                <label htmlFor="">
                  Gross <i className="fa fa-star required"></i>
                </label>
                <input
                  type="number"
                  disabled
                  className="form-control"
                  placeholder="Gross"
                  name="gross"
                  value={grossSalary}
                  onChange={salaryChk}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-3">
                <button
                  className="btn btn-primary btn-block mg-b-10"
                  onClick={formSubmit}
                >
                  <i className="fa fa-send mg-r-10"></i> Create
                </button>
              </div>
            </div>
          </div>
          {/* card */}
        </div>
      </div>
    </div>
  );
};

export default CreateStaff;
